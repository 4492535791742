/**
 * 面授创建API
 */
import { fetch, post, del, downFile} from 'api/base/axios'

const URL = {
  filterUrl: '/question/list/',
  delUrl: '/question/delete/',
  paperQuestionUrl: '/question/paper_questions/',
  submitTestUrl: '/question/test/submit/',
  tagUrl: '/question/tag/',
  claerUrl: '/question/clear/answer/',
  downUrl: '/question/down_que_template/',
  uploadUrl: '/question/upload_que/'
}

/**
 * 查询用户
 * @param data
 * @returns {Promise}
 */
export function listQuestion (data) {
  return post(URL.filterUrl, data)
}

export function delQuestion (id) {
  return del(URL.delUrl + id + '/')
}

export function listPapaerQuestion (paper_id, data) {
  return post(URL.paperQuestionUrl + paper_id + '/', data)
}

export function submitTest(data) {
  return post(URL.submitTestUrl, data)
}

export function addUserTag(data) {
  return post(URL.tagUrl, data)
}

export function clearPaperAnswer(paper_id) {
  return post(URL.claerUrl+ paper_id + '/')
}

export function downQueTemplate(){
  return downFile(URL.downUrl)
}

export function uploadQue(params){
  return post(URL.uploadUrl, params)
}
