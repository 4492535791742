<template>
  <div class="wrap">
    <div class="tabs">
      <!-- 切换 -->
      <el-radio-group v-model="tabActiveName" class="radio_tabs" size="medium" @change="initErrorPage">
        <el-radio-button label="total">全部</el-radio-button>
        <el-radio-button label="wrong">错题集</el-radio-button>
      </el-radio-group>
    </div>
    <div class="content" v-if="tabActiveName == 'total'">
      <el-alert class="alert" :closable="false" type="info">
        <template #title>
          <el-row>
            <el-col :span="8">正确：{{rightNum}}</el-col>
            <el-col :span="8">错误：{{errorNum}}</el-col>
            <el-col :span="8">正确率：{{acc}}%</el-col>
          </el-row>
        </template>
      </el-alert>
      <dl class="exercise ueditor_style" ref="questions">
        <div v-if="judgeChoice.length > 0">
          <dt><span>判断题</span></dt>
          <dd>
            <ul>
              <li v-for="(item, index) in judgeChoice" :key="item.paper_question_id">
                <div class="no">{{ index+1 }}.</div>
                <div class="content">
                  <div class="stem" v-html="item.stem"></div>
                  <div class="options">
                    <el-radio-group v-model="item.selected"
                                    :disabled="hasSubmit">
                      <el-radio label="1" key="1">
                        <div class="opts_content">
                          <p>对</p>
                        </div>
                      </el-radio>
                      <el-radio label="0" key="0">
                        <div class="opts_content">
                          <p>错</p>
                        </div>
                      </el-radio>
                    </el-radio-group>
                  </div>
                  <div class="analysis" v-if="hasSubmit">
                    <div class="answer">
                      <span class="success" v-if="item.is_right"><i class="el-icon-check"></i>回答正确</span>
                      <span class="error" v-else><i class="el-icon-close"></i>回答错误</span>
                      <span>正确答案：{{ item.answer_str }}</span>
                    </div>
                    <div class="tip" v-if="item.analysis">
                      <el-tag size="medium">试题解析</el-tag>
                      <p v-html="item.analysis"></p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </dd>
        </div>
        <div v-if="singleChoiceList.length > 0">
          <dt><span>单选题</span></dt>
          <dd>
            <ul>
              <li v-for="(item, index) in singleChoiceList" :key="item.paper_question_id">
                <div class="no">{{ index+1 }}.</div>
                <div class="content" >
                  <div class="stem" v-html="item.stem"></div>
                  <div class="options">
                    <el-radio-group v-model="item.selected">
                      <el-radio :label="option.identification" v-for="option in item.options" :key="option.identification"
                                :disabled="hasSubmit">
                        <div class="opts_no">{{option.identification}}. </div>
                        <div class="opts_content" v-html="option.option"></div>
                      </el-radio>
                    </el-radio-group>
                  </div>
                  <div class="analysis" v-if="hasSubmit">
                    <div class="answer">
                      <span class="success" v-if="item.is_right"><i class="el-icon-check"></i>回答正确</span>
                      <span class="error" v-else><i class="el-icon-close"></i>回答错误</span>
                      <span>正确答案：{{ JSON.parse(item.answer).join(',') }}</span>
                    </div>
                    <div class="tip" v-if="item.analysis">
                      <el-tag size="medium">试题解析</el-tag>
                      <p v-html="item.analysis"></p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </dd>
        </div>
        <div v-if="multipleChoice.length > 0">
          <dt><span>多选题</span></dt>
          <dd>
            <ul>
              <li v-for="(item, index) in multipleChoice" :key="item.paper_question_id">
                <div class="no">{{ index+1 }}.</div>
                <div class="content">
                  <div class="stem" v-html="item.stem"></div>
                  <div class="options">
                    <el-checkbox-group v-model="item.selected">
                      <el-checkbox :label="option.identification" v-for="option in item.options" :key="option.identification"
                                   :disabled="hasSubmit">
                        <div class="opts_no">{{option.identification}}. </div>
                        <div class="opts_content" v-html="option.option"></div>
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="analysis" v-if="hasSubmit">
                    <div class="answer">
                      <span class="success" v-if="item.is_right"><i class="el-icon-check"></i>回答正确</span>
                      <span class="error" v-else><i class="el-icon-close"></i>回答错误</span>
                      <span>正确答案：{{ JSON.parse(item.answer).join(',') }}</span>
                    </div>
                    <div class="tip" v-if="item.analysis">
                      <el-tag size="medium">试题解析</el-tag>
                      <p v-html="item.analysis"></p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </dl>
      <el-button type="primary" size="medium" @click="onSubmit" v-if="!hasSubmit">提交答题</el-button>
      <el-button type="primary" size="medium" @click="onRestart" v-else>重新答题</el-button>
    </div>
    <div class="content" v-else-if="tabActiveName == 'wrong'">
      <dl class="exercise ueditor_style" ref="questions">
        <div v-if="errorJudgeChoice.length > 0">
          <dt><span>判断题</span></dt>
          <dd>
            <ul>
              <li v-for="(item, index) in errorJudgeChoice" :key="item.paper_question_id">
                <div class="no">{{ index+1 }}.</div>
                <div class="content">
                  <div class="stem" v-html="item.stem"></div>
                  <div class="options">
                    <el-radio-group v-model="item.selected" disabled>
                      <el-radio label="1" key="1">
                        <div class="opts_content">
                          <p>对</p>
                        </div>
                      </el-radio>
                      <el-radio label="0" key="0">
                        <div class="opts_content">
                          <p>错</p>
                        </div>
                      </el-radio>
                    </el-radio-group>
                  </div>
                  <div class="analysis">
                    <div class="answer">
                      <span class="success" v-if="item.is_right"><i class="el-icon-check"></i>回答正确</span>
                      <span class="error" v-else><i class="el-icon-close"></i>回答错误</span>
                      <span>正确答案：{{ item.answer_str }}</span>
                    </div>
                    <div class="tip" v-if="item.analysis">
                      <el-tag size="medium">试题解析</el-tag>
                      <p v-html="item.analysis"></p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </dd>
        </div>
        <div v-if="errorSingleChoice.length > 0">
          <dt><span>单选题</span></dt>
          <dd>
            <ul>
              <li v-for="(item, index) in errorSingleChoice" :key="item.paper_question_id">
                <div class="no">{{ index+1 }}.</div>
                <div class="content" >
                  <div class="stem" v-html="item.stem"></div>
                  <div class="options">
                    <el-radio-group v-model="item.selected" disabled>
                      <el-radio :label="option.identification" v-for="option in item.options" :key="option.identification">
                        <div class="opts_no">{{option.identification}}. </div>
                        <div class="opts_content" v-html="option.option"></div>
                      </el-radio>
                    </el-radio-group>
                  </div>
                  <div class="analysis">
                    <div class="answer">
                      <span class="success" v-if="item.is_right"><i class="el-icon-check"></i>回答正确</span>
                      <span class="error" v-else><i class="el-icon-close"></i>回答错误</span>
                      <span>正确答案：{{ JSON.parse(item.answer).join(',') }}</span>
                    </div>
                    <div class="tip" v-if="item.analysis">
                      <el-tag size="medium">试题解析</el-tag>
                      <p v-html="item.analysis"></p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </dd>
        </div>
        <div v-if="errorMultipleChoice.length > 0">
          <dt><span>多选题</span></dt>
          <dd>
            <ul>
              <li v-for="(item, index) in errorMultipleChoice" :key="item.paper_question_id">
                <div class="no">{{ index+1 }}.</div>
                <div class="content">
                  <div class="stem" v-html="item.stem"></div>
                  <div class="options">
                    <el-checkbox-group v-model="item.selected" disabled>
                      <el-checkbox :label="option.identification" v-for="option in item.options" :key="option.identification">
                        <div class="opts_no">{{option.identification}}. </div>
                        <div class="opts_content" v-html="option.option"></div>
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="analysis">
                    <div class="answer">
                      <span class="success" v-if="item.is_right"><i class="el-icon-check"></i>回答正确</span>
                      <span class="error" v-else><i class="el-icon-close"></i>回答错误</span>
                      <span>正确答案：{{ JSON.parse(item.answer).join(',') }}</span>
                    </div>
                    <div class="tip" v-if="item.analysis">
                      <el-tag size="medium">试题解析</el-tag>
                      <p v-html="item.analysis"></p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </dl>
<!--      <el-empty v-if="errorJudgeChoice.length === 0 && errorSingleChoice.length === 0 && errorMultipleChoice.length === 0" description="暂无内容"></el-empty>-->
<!--      <el-button type="primary" size="medium" @click="onSubmit" v-if="!hasSubmit">提交答题</el-button>-->
<!--      <el-button type="primary" size="medium" @click="onRestart" v-else>重新答题</el-button>-->
    </div>
  </div>
</template>
<script>
  import {listPapaerQuestion, submitTest, clearPaperAnswer} from "api/question/list"
  export default {
    props: {
      paper_ids: Array,
      cur_name: String,
    },
    emits: ['getAnswers', 'getSubmit'],
    data() {
      return {
        paper_id: this.paper_ids[0],
        tabActiveName: 'total',
        singleChoiceList: [],
        multipleChoice: [],
        judgeChoice: [],
        errorSingleChoice: [],
        errorMultipleChoice: [],
        errorJudgeChoice: [],
        radio: '0',
        checkList: [],
        hasSubmit: false,
        rightNum: '-',
        errorNum: '-',
        acc: '-',
        notSubmit: true,
        recordAnswers: {
          singleChoiceList:[],
          multipleChoice: [],
          judgeChoice: [],
        }
      }
    },
    mounted(){
      this.listQuestions()
    },
    methods: {
      listQuestions() {
        const that = this
        let filters = {need_answer: true, total: true}
        that.hasSubmit = false
        listPapaerQuestion(this.paper_id, filters).then(res => {

          if(sessionStorage.getItem('answers')) { console.log('试题重新赋值！！！');
            that.singleChoiceList = that.recordAnswers.singleChoiceList = JSON.parse(sessionStorage.getItem('answers')).singleChoiceList
            that.multipleChoice = that.recordAnswers.multipleChoice = JSON.parse(sessionStorage.getItem('answers')).multipleChoice
            that.judgeChoice = that.recordAnswers.judgeChoice = JSON.parse(sessionStorage.getItem('answers')).judgeChoice
          } else {
            that.singleChoiceList = that.recordAnswers.singleChoiceList = res.single_choice_list
            that.multipleChoice = that.recordAnswers.multipleChoice = res.multi_choice_list
            that.judgeChoice = that.recordAnswers.judgeChoice = res.true_false_list
          }

          let right_num = 0, error_num = 0, acc = 0
          that.singleChoiceList.forEach(item => {
            if(item.user_answer) {
              item.hasSubmit = true
              item.selected = item.user_answer.answer
              item.is_right = item.user_answer.is_right
              if(item.user_answer.is_right === true){
                right_num ++
              }
              else if(item.user_answer.is_right === false){
                error_num ++
              }
            }
          })
          that.multipleChoice.forEach(item => {
            if(item.user_answer) {
              that.hasSubmit = true
              item.selected = item.user_answer.answer.split(',')
              item.is_right = item.user_answer.is_right
              if(item.user_answer.is_right === true){
                right_num ++
              }
              else if(item.user_answer.is_right === false){
                error_num ++
              }
            }
          })
          that.judgeChoice.forEach(item => {
            if(item.user_answer) {
              that.hasSubmit = true
              item.selected = item.user_answer.answer
              item.is_right = item.user_answer.is_right
              if(item.user_answer.is_right === true){
                right_num ++
              }
              else if(item.user_answer.is_right === false){
                error_num ++
              }
              let answer = JSON.parse(item.answer)
              item.answer_str = answer[0] == 1 ? '对' : '错'
            }
          })

          console.log('that.hasSubmit='+that.hasSubmit);
          this.$emit('getSubmit', this.hasSubmit)

          if(that.hasSubmit){
            that.rightNum = right_num
            that.errorNum = error_num
            that.acc = (right_num * 100 / (right_num + error_num)).toFixed(2)
          }
          else {
            that.rightNum = '-'
            that.errorNum = '-'
            that.acc = '-'
          }

        })
      },
      initErrorPage() {
        if(this.tabActiveName == 'wrong'){
          const that = this
          this.getErrorQuestions()
        }
      },
      getErrorQuestions() {
        let filters = {
          paper_id: this.paper_id,
          need_answer: true,
          is_right: false
        }
        const that = this
        listPapaerQuestion(this.paper_id, filters).then(res => {
          that.errorSingleChoice = res.single_choice_list
          that.errorMultipleChoice = res.multi_choice_list
          that.errorJudgeChoice = res.true_false_list
          that.errorSingleChoice.forEach(item => {
            item.selected = item.user_answer.answer
          })
          that.errorMultipleChoice.forEach(item => {
            item.selected = item.user_answer.answer.split(',')
          })
          that.errorJudgeChoice.forEach(item => {
            item.selected = item.user_answer.answer
            let answer = JSON.parse(item.answer)
            item.answer_str = answer[0] == 1 ? '对' : '错'
          })
        })
      },
      onSubmit() {
        let data = []
        let right_num = 0, error_num = 0, acc = 0
        this.singleChoiceList.forEach((item) => {
          let is_right = false
          if(item.selected == JSON.parse(item.answer)[0]){
            is_right = true
            right_num ++
          }
          else {
            error_num ++
          }
          item.is_right = is_right
          data.push({
            paper_id: this.paper_id,
            question_id: item.id,
            answer: item.selected,
            is_right: is_right
          })
        })
        this.multipleChoice.forEach((item) => {
          let is_right = false
          let answer = JSON.parse(item.answer).sort()
          if(item.selected.sort().join(',') == answer.join(',')){
            is_right = true
            right_num ++
          }
          else {
            error_num ++
          }
          item.is_right = is_right
          data.push({
            paper_id: this.paper_id,
            question_id: item.id,
            answer: item.selected.join(','),
            is_right: is_right
          })
        })
        this.judgeChoice.forEach((item) => {
          let is_right = false
          let answer = JSON.parse(item.answer)
          if(item.selected == answer[0]){
            is_right = true
            right_num ++
          }
          else {
            error_num ++
          }
          item.is_right = is_right
          item.answer_str = answer[0] == 1 ? '对' : '错'
          data.push({
            paper_id: this.paper_id,
            question_id: item.id,
            answer: item.selected,
            is_right: is_right
          })
        })
        const that = this
        let post_data = {
          cur_name: this.cur_name,
          values: data
        }
        submitTest(post_data).then(res => {
          if(res.code == 0){
            console.log('====提交答案')
            that.hasSubmit = true
            that.rightNum = right_num
            that.errorNum = error_num
            that.acc = (right_num * 100 / (right_num + error_num)).toFixed(2)
            this.$emit('getSubmit', this.hasSubmit)
          }
        })
      },
      onRestart() {
        const that = this
        clearPaperAnswer(that.paper_id).then(res => {
          if(res.code == 0){
            this.clearSession()
            that.listQuestions()
          }
        })
        // this.singleChoiceList.forEach((item) => {
        //   delete item['selected']
        //   delete item['is_right']
        // })
        // this.multipleChoice.forEach((item) => {
        //   item['selected'] = []
        //   delete item['is_right']
        // })
        // this.judgeChoice.forEach((item) => {
        //   delete item['selected']
        //   delete item['is_right']
        //   delete  item['answer_str']
        // })
        // console.log(this.hasSubmit)
      },
      clearSession() {
        if(sessionStorage.getItem('answers')) {
          sessionStorage.removeItem('answers')
        }
        if(sessionStorage.getItem('is_submit')) {
          sessionStorage.removeItem('is_submit')
        }
      }
    },
    watch: {
      judgeChoice: {
        handler(newVal, oldVal) {
          this.$emit('getAnswers', this.recordAnswers)
        },
        deep: true
      },
      singleChoiceList: {
        handler(newVal, oldVal) {
          this.$emit('getAnswers', this.recordAnswers)
        },
        deep: true
      },
      multipleChoice: {
        handler(newVal, oldVal) {
          this.$emit('getAnswers', this.recordAnswers)
        },
        deep: true
      },
      // hasSubmit(newVal, oldVal) {
      //     console.log('watch hasSubmit='+newVal)
      //     this.$emit('getSubmit', this.hasSubmit)
      // }
    }
  }
</script>

<style lang="scss">
  @import "~@/assets/css/ueditor.scss";
</style>
<style lang="scss" scoped>
  .wrap {
    padding:15px;
  }
  .tabs {
    margin-bottom:15px;
  }
  .content {
    :deep(.alert) {
      margin-bottom:10px;
      .el-alert__title {
        font-size:12px;
      }
      &.el-alert--info {
        .el-alert__content {
          flex:1;
          color:#666;
        }
      }
    }
  }
  .exercise {
    dt {
      margin-bottom:15px;
      > span {
        padding:5px 15px;
        background:#ff9900;
        font-size:12px;
        color:#fff;
        position:relative;
        &:before {
          content:'';
          width:2px;
          position:absolute;
          left:3px;
          top:0;
          bottom:0;
          background:#fff;
        }
      }
    }
    dd {
      margin:0;
      ul {
        li {
          display:flex;
          line-height:1.5;
          color:#333;
          padding-bottom:10px;
          border-bottom:2px solid #f2f2f2;
          margin-bottom:15px;
          .no {
            width:40px;
            margin-right:10px;
            text-align: center;
          }
          .content {
            flex: 1;
            :deep(.stem) {
              margin-bottom:10px;
              /*padding-bottom:5px;*/
              p {
                margin-top:0;
                img {
                  max-width:100%;
                }
              }
            }
            :deep(.options) {
              .el-radio, .el-checkbox {
                display:flex;
                margin-right:0;
                margin-bottom: 15px;
                /*margin-bottom:10px;*/
                .el-radio__input, .el-checkbox__input {
                  padding-top:2px;
                }
                .el-radio__label, .el-checkbox__label {
                  font-size:12px;
                  white-space: normal;
                  line-height:1.5;
                  display:flex;
                  .opts_no {
                    margin-right:5px;
                  }
                  .opts_content {
                    p {
                      margin:0;
                    }
                    img {
                      max-width:100%;
                    }
                  }
                }

              }
            }
          }
        }
      }
    }
  }
  .analysis {
    padding:15px;
    font-size:12px;
    background:#f8f8f8;
    .answer {
      > span {
        margin-right:20px;
        > i {
          margin-right:5px;
        }
        &.success {
          color:#6ecd3f;
        }
        &.error {
          color:#f51c21;
        }
      }
    }
    .tip {
      margin-top:15px;
      .el-tag {
        margin-bottom:15px;
      }
      > p {
        margin:0;
      }
    }
  }
</style>
