import { fetch, post } from 'api/base/axios'

const URL = {
  initUrl: '/curriculum/cur/init_user_page/',
  initExerciseUrl: '/curriculum/cur/init_exercise_page/',
  loginUrl: '/curriculum/cur/login_state/',
  recordUrl: '/curriculum/cur/user_record/',
  userCurChapterUrl: '/curriculum/cur/chapter_list/'
}

export function init(data){
  return fetch(URL.initUrl, data)
}

export function initExercisePage(id){
  return fetch(URL.initExerciseUrl + id + '/')
}

export function checkLogin() {
  return fetch(URL.loginUrl)
}

export function getCurRecord(data) {
  return fetch(URL.recordUrl, data)
}

export function getCurChapter(data){
  return fetch(URL.userCurChapterUrl, data)
}
