<template>
  <div>
    <!-- 若配置了多个课件 -->
    <div class="form_wrap" v-if="courseware.list.length > 0">
      <el-form>
        <el-form-item label="课件">
          <el-select v-model="courseware.selected" placeholder="请选择" size="small">
            <el-option
              v-for="item in courseware.list"
              :key="item.ware_url"
              :label="item.name"
              :value="item.ware_url">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="iframe_wrap" :style="courseware.list.length > 0 ? 'top: 61px' : 'top: 0;'">
      <iframe :src="pdf_path + 'web/viewer.html?file=' + encodeURIComponent(courseware.selected)" height="100%" width="100%"></iframe>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      courseware_list: Array,
      selected_index: {
        type: Number,
        default: 0
      }
    },
    emits: ['getCourseWareSelectedIndex'],
    data() {
      return {
        url: '',
        coursewareSelected: '',
        courseware: {
          selected: '',
          list: this.courseware_list,
        },
        pdf_path : process.env.NODE_ENV === 'production' ? `${process.env.BASE_URL}pdfjs/` : '/pdfjs/',
      }
    },
    mounted() {
      if(this.selected_index === 0) {
        this.courseware.selected = this.courseware_list[0].ware_url
      } else {
        this.courseware.selected = this.courseware_list[this.selected_index].ware_url
      }
      let Base64 = require('js-base64').Base64
      this.url = this.pdf_path + 'web/viewer.html?file=' + this.courseware.selected
    },
    watch: {
      courseware_list (val) {
        this.courseware.list = val
        this.courseware.selected = val[0].ware_url
        this.url = this.pdf_path + 'web/viewer.html?file=' + this.courseware.selected
      },
      courseware: {
        handler(newVal, oldVal) {

          const index = this.courseware_list.findIndex(item => {
            return item.ware_url === newVal.selected
          })

          this.$emit('getCourseWareSelectedIndex', index)
        },
        deep:true
      }
    },
  }
</script>

<style lang="scss" scoped>
  .form_wrap {
    padding:10px 15px;
    border-bottom: 1px solid #E4E7ED;
    .el-form-item {
      margin:0;
    }
  }
  .iframe_wrap {
    position:absolute;
    top:61px;
    left:0;
    right:0;
    bottom:0;
  }
</style>
